import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import './Updates.css';

function Updates() {
  const [changelogVisible, setChangelogVisible] = useState<string[]>([]);

  return (
    <div className="updates">
      <h3>Updates</h3>
      <p>
        We release two types of updates for PhraseForge Slovak:{' '}
        <b>content updates</b> and <b>app updates</b>.
      </p>
      <p>
        App updates include bug fixes, performance improvements, and new
        features. App updates are often automatically downloaded, but you can
        also manually check for updates in the app store.
      </p>
      <p>
        Content updates include new and revised lessons, exercises, and other
        educational content. You will always automatically use the latest
        content when you use the app, and the same content is available on all
        platforms.
      </p>
      <p>
        Below is a list of recent app and content updates, with the most recent
        at the top.
      </p>
      <h4>Update List</h4>
      <ul>
        <li>
          <b>2024-12-13</b>
          <ul>
            <li>
              <p>
                Patch <b>v1.3.4</b> is released on the Apple App Store and
                Google Play store.
              </p>
              <p>
                This patch adds interface improvements and fixes, and continues
                to improve the accuracy of the Speech Input feature.
              </p>
              <Link
                className={
                  changelogVisible.includes('v1.3.4') ? 'hidden' : 'visible'
                }
                onClick={() =>
                  setChangelogVisible([...changelogVisible, 'v1.3.4'])
                }
                to={'#'}
              >
                Display full changelog
              </Link>
              {changelogVisible.includes('v1.3.4') && (
                <>
                  <b>Full changelog:</b>
                  <ul>
                    <li>
                      Fix a rare issue where the correct option for drills was
                      not displayed
                    </li>
                    <li>Improve line wrapping of exercise prompts</li>
                    <li>
                      Add clarifying note to General Revision lesson conclusion
                      about mastery ratings
                    </li>
                    <li>Further improvements to Speech Input accuracy</li>
                  </ul>
                </>
              )}
            </li>
            <br />
          </ul>
        </li>
        <li>
          <b>2024-12-01</b>
          <ul>
            <li>
              <p>
                Patch <b>v1.3.3</b> is released on the Apple App Store and
                Google Play store.
              </p>
              <p>
                This patch improves the accuracy of the Speech Input feature,
                especially for new material in the November content update.
              </p>
              <Link
                className={
                  changelogVisible.includes('v1.3.3') ? 'hidden' : 'visible'
                }
                onClick={() =>
                  setChangelogVisible([...changelogVisible, 'v1.3.3'])
                }
                to={'#'}
              >
                Display full changelog
              </Link>
              {changelogVisible.includes('v1.3.3') && (
                <>
                  <b>Full changelog:</b>
                  <ul>
                    <li>
                      Improvements to the accuracy of the Speech Input feature
                    </li>
                  </ul>
                </>
              )}
            </li>
            <br />
          </ul>
        </li>
        <li>
          <b>2024-11-30</b>
          <ul>
            <li>
              <p>
                Our November content update, featuring 4 new topics:{' '}
                <b>Adjectives 2</b>, <b>Comparative</b>, <b>Clothing</b>, and{' '}
                <b>Work</b>, is released. This release also includes revisions
                to existing exercises and help topics.
              </p>
            </li>
          </ul>
          <br />
        </li>
        <li>
          <b>2024-11-22</b>
          <ul>
            <li>
              <p>
                Patch <b>v1.3.2</b> is released on the Apple App Store and
                Google Play store.
              </p>
              <p>
                This patch improves the accuracy of the Speech Input feature.
              </p>
              <Link
                className={
                  changelogVisible.includes('v1.3.2') ? 'hidden' : 'visible'
                }
                onClick={() =>
                  setChangelogVisible([...changelogVisible, 'v1.3.2'])
                }
                to={'#'}
              >
                Display full changelog
              </Link>
              {changelogVisible.includes('v1.3.2') && (
                <>
                  <b>Full changelog:</b>
                  <ul>
                    <li>
                      Improvements to the accuracy of the Speech Input feature
                    </li>
                  </ul>
                </>
              )}
            </li>
            <br />
          </ul>
        </li>
        <li>
          <b>2024-11-17</b>
          <ul>
            <li>
              <p>
                Patch <b>v1.3.1</b> is released on the Apple App Store and
                Google Play store.
              </p>
              <p>
                This patch fixes bugs and improves the accuracy of the Speech
                Input feature.
              </p>
              <Link
                className={
                  changelogVisible.includes('v1.3.1') ? 'hidden' : 'visible'
                }
                onClick={() =>
                  setChangelogVisible([...changelogVisible, 'v1.3.1'])
                }
                to={'#'}
              >
                Display full changelog
              </Link>
              {changelogVisible.includes('v1.3.1') && (
                <>
                  <b>Full changelog:</b>
                  <ul>
                    <li>
                      Fix display issues when using Speech Input in combination
                      with Vocab Drills
                    </li>
                    <li>
                      Improve user experience when Speech Input processing has
                      an internal server error
                    </li>
                    <li>
                      Improvements to the accuracy of the Speech Input feature
                    </li>
                  </ul>
                </>
              )}
            </li>
            <br />
          </ul>
        </li>
        <li>
          <b>2024-11-02</b>
          <ul>
            <li>
              <p>
                Update <b>v1.3.0</b> is released on the Apple App Store and
                Google Play store. (The Android version number has skipped v.1.1
                and v.1.2 in order to match the Apple version number.)
              </p>
              <p>
                This update introduces our <b>Speech Input</b> feature, allowing
                the user to practice speaking by entering their answers via the
                microphone as an alternative to typing. This feature is freely
                available during a user&apos;s initial lesson but requires a
                subscription to be activated for all subsequent lessons.
                Activate it with the microphone icon next to the
                &quot;Check&quot; button for any exercise that can be answered
                by typing.
              </p>
              <p>
                The update also includes various other minor features, bug fixes
                and interface improvements.
              </p>
              <Link
                className={
                  changelogVisible.includes('v1.3.0') ? 'hidden' : 'visible'
                }
                onClick={() =>
                  setChangelogVisible([...changelogVisible, 'v1.3.0'])
                }
                to={'#'}
              >
                Display full changelog
              </Link>
              {changelogVisible.includes('v1.3.0') && (
                <>
                  <b>Full changelog:</b>
                  <ul>
                    <li>
                      Add new major feature: <b>Speech Input</b>. The user can
                      complete lessons by speaking as an alternative to typing
                      (requires subscription).
                    </li>
                    <li>
                      Add button to toggle between app and system keyboard while
                      completing an exercise
                    </li>
                    <li>Redesign app and company logos</li>
                    <li>
                      Extend introduction sequence to prompt user to set daily
                      reminder notifications
                    </li>
                    <li>
                      Add occasional prompt to review the app after completing
                      lessons
                    </li>
                    <li>
                      Fix popup tips briefly appearing on app reload when
                      they&apos;ve already been seen
                    </li>
                    <li>Minor text and UI adjustments</li>
                  </ul>
                </>
              )}
            </li>
            <br />
          </ul>
        </li>
        <li>
          <b>2024-10-14</b>
          <ul>
            <li>
              <p>
                Our October content update, featuring 4 new topics: <b>Body</b>,{' '}
                <b>Direction</b>, <b>Future</b>, and <b>Months</b>, is released.
                This release also includes corrections to existing exercises as
                well as additions and edits to our help topics, in particular
                expanding on the details of the instrumental case.
              </p>
            </li>
          </ul>
          <br />
        </li>
        <li>
          <b>2024-09-29</b>
          <ul>
            <li>
              <p>
                Patch <b>v1.2.1</b> is released on the Apple App Store and{' '}
                <b>v1.0.1</b> is released on the Google Play store.
              </p>
              <p>
                This patch makes several bug fixes and interface improvements.
              </p>
              <Link
                className={
                  changelogVisible.includes('v1.2.1') ? 'hidden' : 'visible'
                }
                onClick={() =>
                  setChangelogVisible([...changelogVisible, 'v1.2.1'])
                }
                to={'#'}
              >
                Display full changelog
              </Link>
              {changelogVisible.includes('v1.2.1') && (
                <>
                  <b>Full changelog:</b>
                  <ul>
                    <li>
                      Require a long press to mark Vocab Drills as
                      correct/incorrect, and add a popup dialog for short press
                    </li>
                    <li>
                      Fix issue where popup tips would sometimes be shown again
                      after they had already been seen
                    </li>
                    <li>
                      Add popup dialogs for (otherwise unresponsive) completed
                      exercises and selected words
                    </li>
                    <li>
                      Add a popup tip to clarify that incorrectly answered
                      exercises are repeated at the end of the lesson
                    </li>
                    <li>
                      Adjust exercise feedback to more clearly indicate how the
                      user should fix a minor mistake
                    </li>
                    <li>
                      More appropriate icons for website links (from Info &
                      Feedback)
                    </li>
                    <li>
                      Make welcome screen button position consistent across
                      consecutive initial screens
                    </li>
                    <li>
                      Make popup dialog for &quot;Delete Account&quot; button
                      more helpful
                    </li>
                    <li>
                      Fix typos in popup dialog for look-ahead instruction box
                      icon
                    </li>
                  </ul>
                </>
              )}
            </li>
            <br />
          </ul>
        </li>
        <li>
          <b>2024-09-20</b>
          <ul>
            <li>
              <p>
                Our initial <b>v1.0.0</b> PhraseForge Slovak is released on the
                Google Play store! This release has the same features and
                content as the v.1.2.0 release on the Apple App Store.
              </p>
            </li>
          </ul>
          <br />
        </li>
        <li>
          <b>2024-09-11</b>
          <ul>
            <li>
              <p>
                App Update <b>v1.2.0</b> is released on the Apple App Store.
              </p>
              <p>
                This update adds a new &quot;Info & feedback&quot; settings
                screen, allowing the user to send general feedback on the state
                of the app, along with links to our website and policies.
              </p>
              <p>
                It also adds popup dialogs explaining a range of disabled
                buttons and other non-interactable icons/textboxes, along with
                various bug fixes and interface improvements.
              </p>
              <Link
                className={
                  changelogVisible.includes('v1.2.0') ? 'hidden' : 'visible'
                }
                onClick={() =>
                  setChangelogVisible([...changelogVisible, 'v1.2.0'])
                }
                to={'#'}
              >
                Display full changelog
              </Link>
              {changelogVisible.includes('v1.2.0') && (
                <>
                  <b>Full changelog:</b>
                  <ul>
                    <li>
                      Add new settings screen section &quot;Info &
                      feedback&quot;. The user can send general feedback and
                      follow links to the PhraseForge website, privacy policy
                      and terms of use.
                    </li>
                    <li>
                      Add popup dialogs for a range of disabled buttons and
                      (otherwise) non-interactable icons/textboxes.
                    </li>
                    <li>Add activity indicator for loading help topics</li>
                    <li>
                      Reload user status after a long time without using the app
                      (was previously just lost, resulting in incorrect
                      functionality)
                    </li>
                    <li>
                      Fix error/crash when submitting feedback for Vocab Drills
                    </li>
                    <li>
                      Fix issues where explanatory popup tips sometimes appear
                      at the wrong time
                    </li>
                    <li>
                      Fix &quot;See subscriptions&quot; button not working when
                      there is no trial available
                    </li>
                    <li>
                      Add popup tips for the settings button and settings menu
                    </li>
                    <li>Tweak wording of some introductory screens</li>
                    <li>
                      Add colored shading for selected tiles for Match Words
                      exercises.
                    </li>
                  </ul>
                </>
              )}
            </li>
            <br />
          </ul>
        </li>
        <li>
          <b>2024-08-17</b>
          <ul>
            <li>
              <p>
                Patch <b>v1.1.1</b> is released on the Apple App Store.
              </p>
              <p>
                This update fixes a couple of minor issues with the new
                introduction sequence.
              </p>
              <Link
                className={
                  changelogVisible.includes('v1.1.1') ? 'hidden' : 'visible'
                }
                onClick={() =>
                  setChangelogVisible([...changelogVisible, 'v1.1.1'])
                }
                to={'#'}
              >
                Display full changelog
              </Link>
              {changelogVisible.includes('v1.1.1') && (
                <>
                  <b>Full changelog:</b>
                  <ul>
                    <li>
                      Fix user unintentionally being offered free trial too
                      early in the sequence and being offered it a second time
                      even if they refuse
                    </li>
                    <li>
                      Fix anonymised measurement for user acceptance of the
                      introduction sequence failing to communicate to the server
                    </li>
                  </ul>
                </>
              )}
            </li>
            <br />
          </ul>
        </li>
        <li>
          <b>2024-08-16</b>
          <ul>
            <li>
              <p>
                App Update <b>v1.1.0</b> is released on the Apple App Store.
              </p>
              <p>
                This update includes a new introduction sequence for first-time
                users, allowing the completion of the first lesson before
                creating an account. It also includes various bug fixes and
                improvements.
              </p>
              <Link
                className={
                  changelogVisible.includes('v1.1.0') ? 'hidden' : 'visible'
                }
                onClick={() =>
                  setChangelogVisible([...changelogVisible, 'v1.1.0'])
                }
                to={'#'}
              >
                Display full changelog
              </Link>
              {changelogVisible.includes('v1.1.0') && (
                <>
                  <b>Full changelog:</b>
                  <ul>
                    <li>
                      Add introduction sequence for first-time users, allowing
                      the completion of the first lesson before creating an
                      account.
                    </li>
                    <li>
                      Remove subscription/free trial promotional material from
                      screen once user initiates the App Store-mediated purchase
                      flow
                    </li>
                    <li>
                      Store notifications and &quot;intro tips&quot; that user
                      has seen are now stored server-side so that they persist
                      after logging out/clearing data/reinstalling and
                      aren&apos;t shown again
                    </li>
                    <li>
                      Make mastery ratings are now only visible with a
                      subscription (as advertised)
                    </li>
                    <li>
                      Add text to explain if there are no notifications to
                      display on the notifications screen
                    </li>
                    <li>
                      Fix paywall and subscription screens not handling free
                      trials measured in weeks
                    </li>
                    <li>
                      Fix various UI issues relating to setting/disabling Daily
                      Reminders: set time sometimes not correctly updated,
                      disable button is shown when reminders are already
                      disabled, initial time is not set to current reminder time
                    </li>
                    <li>
                      Fix one-character Slovak words in Vocab Drills incorrectly
                      offering audio for the English translation
                    </li>
                    <li>Adjust button alignment in App Settings</li>
                    <li>
                      Delete account option now deletes Cognito user pool
                      account immediately
                    </li>
                  </ul>
                </>
              )}
            </li>
            <br />
          </ul>
        </li>
        <li>
          <b>2024-08-13</b>
          <ul>
            <li>
              <p>
                Our first content update, featuring 4 new topics: <b>Days</b>,{' '}
                <b>Weather</b>, <b>Food</b>, and <b>Instrumental</b>, is
                released. This release also includes extensive revisions to
                existing topics for improved clarity and pacing.
              </p>
            </li>
          </ul>
          <br />
        </li>
        <li>
          <b>2024-07-25</b>
          <ul>
            <li>
              <p>
                The initial <b>v1.0</b> release of PhraseForge Slovak is
                released on the Apple App Store, featuring the initial 44 topics
                (up to &quot;Residence&quot;).
              </p>
            </li>
          </ul>
          <br />
        </li>
      </ul>
      <div className="disclaimer">
        Apple is a trademark of Apple Inc. in the U.S. and other countries. App
        Store is a service mark of Apple Inc. Google Play is a trademark of
        Google LLC.
      </div>
    </div>
  );
}

export default Updates;
