import React, { useState } from 'react';

import { NavLink } from 'react-router-dom';

import { ShowTips } from '../../util/Values';

import './Nav.css';

function Nav() {
  const [appDropdownOpen, setAppDropdownOpen] = useState(false);
  const [appDropdownKeptOpen, setAppDropdownKeptOpen] = useState(false);

  return (
    <nav className="nav">
      <div className="nav-cap" />
      <div className="nav-item">
        <NavLink
          className="nav-interior"
          onClick={() => setAppDropdownOpen(true)}
          onMouseEnter={() => setAppDropdownOpen(true)}
          onMouseLeave={() => setAppDropdownOpen(false)}
          to="/"
        >
          PhraseForge Slovak App
        </NavLink>
        {(appDropdownOpen || appDropdownKeptOpen) && (
          <div className="dropdown-content">
            <NavLink
              className="dropdown-item"
              to="/"
              onClick={() => setAppDropdownOpen(false)}
              onMouseEnter={() => setAppDropdownKeptOpen(true)}
              onMouseLeave={() => setAppDropdownKeptOpen(false)}
            >
              Overview
            </NavLink>
            <NavLink
              className="dropdown-item"
              to="/course-design"
              onClick={() => {
                setAppDropdownOpen(false);
                setAppDropdownKeptOpen(false);
              }}
              onMouseEnter={() => setAppDropdownKeptOpen(true)}
              onMouseLeave={() => setAppDropdownKeptOpen(false)}
            >
              Course Design
            </NavLink>
            <NavLink
              className="dropdown-item"
              to="/lesson-design"
              onClick={() => {
                setAppDropdownOpen(false);
                setAppDropdownKeptOpen(false);
              }}
              onMouseEnter={() => setAppDropdownKeptOpen(true)}
              onMouseLeave={() => setAppDropdownKeptOpen(false)}
            >
              Lesson Design
            </NavLink>
            <NavLink
              className="dropdown-item"
              to="/mastery-ratings"
              onClick={() => {
                setAppDropdownOpen(false);
                setAppDropdownKeptOpen(false);
              }}
              onMouseEnter={() => setAppDropdownKeptOpen(true)}
              onMouseLeave={() => setAppDropdownKeptOpen(false)}
            >
              Mastery Ratings
            </NavLink>
            <NavLink
              className="dropdown-item"
              to="/language-coverage"
              onClick={() => {
                setAppDropdownOpen(false);
                setAppDropdownKeptOpen(false);
              }}
              onMouseEnter={() => setAppDropdownKeptOpen(true)}
              onMouseLeave={() => setAppDropdownKeptOpen(false)}
            >
              Language Coverage
            </NavLink>
          </div>
        )}
      </div>
      {ShowTips && (
        <div className="nav-item">
          <NavLink className="nav-interior" to="/slovak-tips">
            Slovak Tips
          </NavLink>
        </div>
      )}
      <div className="nav-item">
        <NavLink className="nav-interior" to="/plans-and-pricing">
          Plans and Pricing
        </NavLink>
      </div>
      <div className="nav-item">
        <NavLink className="nav-interior" to="/updates">
          Updates
        </NavLink>
      </div>
      <div className="nav-cap" />
    </nav>
  );
}

export default Nav;
