import React from 'react';

import './LanguageCoverage.css';
import { Link } from 'react-router-dom';

import { TopicCount } from '../../util/Values';

function LanguageCoverage() {
  return (
    <div className="updates">
      <h3>Language Coverage</h3>
      <p>
        Our Slovak course is organised into <em>topics</em>. Each topic title
        indicates a key concept or area of focus introduced within that topic,
        but we typically cover several interrelated concepts in each of our
        topics.
      </p>
      <p>Each topic includes:</p>
      <ul>
        <li>
          An <em>introductory lesson</em>, featuring explanations for new Slovak
          concepts combined with simpler exercises to help you become familiar
          with them.
        </li>
        <li>
          A <em>review lesson</em>, beginning with a recap of the new concepts,
          followed by exercises that gradually increase in difficulty
        </li>
        <li>
          A variety of <em>revision exercises</em> to practice the new concepts
          at a level suited to your current{' '}
          <Link to="/mastery-ratings">mastery rating</Link>.
        </li>
      </ul>
      <p>
        For insights into how we structure the Slovak course, see our article on{' '}
        <Link to="/course-design">course design</Link>. To learn how we
        introduce new concepts within a topic, refer to our article on{' '}
        <Link to="/lesson-design">lesson design</Link>.
      </p>
      <p>
        We&apos;re continually adding new topics to our courses and enhancing
        the existing ones.
      </p>
      <p>
        Below is a list of the {TopicCount} topics we&apos;ve covered so far in
        our Slovak course, along with an outline of what&apos;s included in each
        topic.
      </p>
      <h4>List of topics</h4>
      <ul>
        <li>
          <strong>Introduction</strong>
          <ul>
            <li>The Slovak alphabet&apos;s unaccented letters</li>
            <li>A few familiar nouns</li>
            <li>Your first complete Slovak sentences</li>
          </ul>
        </li>
        <li>
          <strong>His and Her</strong>
          <ul>
            <li>The letters C, Š, Č and Ž</li>
            <li>Using possessive pronouns: his and her</li>
            <li>Negation: saying what something is not</li>
          </ul>
        </li>
        <li>
          <strong>Noun Gender</strong>
          <ul>
            <li>Ô and the acute accent</li>
            <li>Introduction to grammatical gender</li>
            <li>Using possessive determiners: my</li>
          </ul>
        </li>
        <li>
          <strong>Yes and No</strong>
          <ul>
            <li>The letters Ch, Í and the letter combination ie</li>
            <li>Using possessive pronouns: their</li>
            <li>I am: How to say who you are (and aren&apos;t)</li>
            <li>Saying yes and no</li>
          </ul>
        </li>
        <li>
          <strong>Formality</strong>
          <ul>
            <li>The letter Ť and the letter combinations te/ti</li>
            <li>Using possessive pronouns: your</li>
            <li>Adressing people formally</li>
            <li>How to say &quot;this&quot;</li>
          </ul>
        </li>
        <li>
          <strong>Verbs</strong>
          <ul>
            <li>An introduction to using Slovak verbs</li>
            <li>First person singular pronouns</li>
            <li>Gender inflection</li>
            <li>The letters Y and Ľ</li>
          </ul>
        </li>
        <li>
          <strong>Accusative</strong>
          <ul>
            <li>Use transitive verbs</li>
            <li>Use the accusative case</li>
            <li>Negate verbs</li>
          </ul>
        </li>
        <li>
          <strong>Questions</strong>
          <ul>
            <li>Asking yes–no questions</li>
            <li>That/this as determiners</li>
            <li>Question words: who, what, why</li>
          </ul>
        </li>
        <li>
          <strong>Adjectives</strong>
          <ul>
            <li>Use adjectives for size, quality and colors</li>
            <li>Express wants</li>
            <li>Use syllabic L</li>
          </ul>
        </li>
        <li>
          <strong>Greetings</strong>
          <ul>
            <li>Some common greetings</li>
            <li>The letters Ň and Ď</li>
            <li>Introduction to reflexive verbs</li>
          </ul>
        </li>
        <li>
          <strong>Infinitive</strong>
          <ul>
            <li>Using infinitives</li>
            <li>Saying what you can do, want to do and have to do</li>
          </ul>
        </li>
        <li>
          <strong>Conjunctions</strong>
          <ul>
            <li>Using basic conjunctions: and, but, that</li>
            <li>The letter Ä</li>
            <li>New vocabulary: food</li>
            <li>New verbs: thinking, knowing, saying, eating</li>
          </ul>
        </li>
        <li>
          <strong>Languages</strong>
          <ul>
            <li>Talking about speaking and understanding languages</li>
            <li>
              Use adverbs for describing how well, or how much, you do something
            </li>
          </ul>
        </li>
        <li>
          <strong>Plurals</strong>
          <ul>
            <li>Plural verb forms, personal pronouns and adjectives</li>
            <li>Adjectives describing people</li>
          </ul>
        </li>
        <li>
          <strong>Numbers</strong>
          <ul>
            <li>Common plural noun forms</li>
            <li>Using the numbers 1 to 4</li>
            <li>Plural determiners: those, these</li>
          </ul>
        </li>
        <li>
          <strong>Greetings 2</strong>
          <ul>
            <li>More greetings, common words and phrases</li>
          </ul>
        </li>
        <li>
          <strong>Reflexive</strong>
          <ul>
            <li>Understanding reflexive verbs</li>
            <li>Using possessive determiners: our</li>
            <li>Possessive determiners for plural nouns</li>
            <li>Using the reflexive possessive determiner</li>
          </ul>
        </li>
        <li>
          <strong>Perfective</strong>
          <ul>
            <li>Understanding perfective and imperfective verbs</li>
            <li>Describing future events</li>
            <li>Adverbs of time</li>
            <li>Buying items</li>
          </ul>
        </li>
        <li>
          <strong>Pronouns</strong>
          <ul>
            <li>Use personal pronouns in the accusative case</li>
            <li>Learn more verbs relating to people</li>
            <li>Use adverbs of place (here, there)</li>
          </ul>
        </li>
        <li>
          <strong>Word Order</strong>
          <ul>
            <li>Recap basic Slovak sentence structure and word order</li>
            <li>Additional vocabulary</li>
          </ul>
        </li>
        <li>
          <strong>Genitive</strong>
          <ul>
            <li>Use the genitive case for singular nouns</li>
            <li>Prepositions for location: next to, near, far from</li>
            <li>Vocabulary for places and buildings</li>
          </ul>
        </li>
        <li>
          <strong>Transport</strong>
          <ul>
            <li>Going to and through places</li>
            <li>Asking where something is going</li>
            <li>Vocabulary relating to transport</li>
          </ul>
        </li>
        <li>
          <strong>Animate</strong>
          <ul>
            <li>Using masculine animate nouns in the accusative case</li>
            <li>Vocabulary for describing people</li>
            <li>Fleeting vowels in masculine nouns</li>
          </ul>
        </li>
        <li>
          <strong>Past Tense</strong>
          <ul>
            <li>Using verbs in the past tense</li>
            <li>Ask when something happened</li>
            <li>Adverbs of time in the past</li>
          </ul>
        </li>
        <li>
          <strong>Family</strong>
          <ul>
            <li>Words for family members</li>
            <li>Masculine animate plurals</li>
          </ul>
        </li>
        <li>
          <strong>Locative</strong>
          <ul>
            <li>Using the locative case</li>
            <li>Prepositions: in, on, about</li>
            <li>Words for rooms of a house</li>
          </ul>
        </li>
        <li>
          <strong>Shopping</strong>
          <ul>
            <li>Clothes and shopping-related vocabulary</li>
            <li>Price, costing and selling</li>
            <li>Expressions: for someone, so expensive</li>
          </ul>
        </li>
        <li>
          <strong>Movement</strong>
          <ul>
            <li>Going places in the future and past</li>
            <li>Timing of events: earlier, later, soon</li>
            <li>More verbs for movement</li>
          </ul>
        </li>
        <li>
          <strong>Likes</strong>
          <ul>
            <li>Talking about likes and dislikes</li>
            <li>Words for common activities</li>
            <li>Personal pronouns in the dative case</li>
          </ul>
        </li>
        <li>
          <strong>House</strong>
          <ul>
            <li>Words for domestic items</li>
            <li>Describing length and cleanliness of objects</li>
          </ul>
        </li>
        <li>
          <strong>Plurals 2</strong>
          <ul>
            <li>
              Additional patterns for nominative and accusative plural endings
            </li>
            <li>New words that use the new plural endings</li>
          </ul>
        </li>
        <li>
          <strong>Numbers 2</strong>
          <ul>
            <li>Numbers 5-10, 100, 1000</li>
            <li>Giving and asking age</li>
            <li>Ordinals: first, second</li>
            <li>Genitive plurals for masculine nouns</li>
          </ul>
        </li>
        <li>
          <strong>Possessives</strong>
          <ul>
            <li>Possessives for first names</li>
            <li>Words for common items and travelling terms</li>
          </ul>
        </li>
        <li>
          <strong>Activities</strong>
          <ul>
            <li>Verbs for general activities: doing, working, using, making</li>
            <li>Talk about what you need</li>
            <li>Words for food you can prepare</li>
          </ul>
        </li>
        <li>
          <strong>Time</strong>
          <ul>
            <li>Describing the frequency of events</li>
            <li>Repeated or habitual movement</li>
            <li>Describing the timing of activities</li>
          </ul>
        </li>
        <li>
          <strong>Imperative</strong>
          <ul>
            <li>
              Making requests and suggesting activites using imperative verbs
            </li>
            <li>More dative personal pronouns</li>
            <li>Verbs for common actions</li>
          </ul>
        </li>
        <li>
          <strong>Greetings 3</strong>
          <ul>
            <li>More greetings, common words and phrases</li>
          </ul>
        </li>
        <li>
          <strong>Emotions</strong>
          <ul>
            <li>Explain how you&apos;re feeling</li>
            <li>Express hunger and thirst</li>
            <li>Talk about your interests and concerns</li>
          </ul>
        </li>
        <li>
          <strong>Speech</strong>
          <ul>
            <li>Vocabulary related to language use</li>
            <li>Using relative pronouns</li>
          </ul>
        </li>
        <li>
          <strong>Conjunctions 2</strong>
          <ul>
            <li>Use more conjunctions and related adverbs</li>
          </ul>
        </li>
        <li>
          <strong>Genitive 2</strong>
          <ul>
            <li>Adverbs for quantities: many, a lot, a few, more, less</li>
            <li>Genitive singular forms ending in -u</li>
          </ul>
        </li>
        <li>
          <strong>Numbers 3</strong>
          <ul>
            <li>Calling telephone numbers</li>
            <li>More numbers: zero, teens</li>
          </ul>
        </li>
        <li>
          <strong>Hours</strong>
          <ul>
            <li>Getting up and going to sleep</li>
            <li>Saying what time something happens</li>
          </ul>
        </li>
        <li>
          <strong>Residence</strong>
          <ul>
            <li>Talking about where you live and where you&apos;re from</li>
            <li>Countries around the world</li>
          </ul>
        </li>
        <li>
          <strong>Days</strong>
          <ul>
            <li>Days of the week</li>
            <li>Words relating to time: next, last, every, weekend</li>
            <li>Taking about when something happens</li>
          </ul>
        </li>
        <li>
          <strong>Weather</strong>
          <ul>
            <li>Talk about the weather and seasons</li>
            <li>Talk about future states</li>
          </ul>
        </li>
        <li>
          <strong>Food</strong>
          <ul>
            <li>Words for meals, food and drink</li>
            <li>Giving items and having food</li>
            <li>Empty and full</li>
            <li>Using the genitive case for &quot;of&quot;</li>
          </ul>
        </li>
        <li>
          <strong>Instrumental</strong>
          <ul>
            <li>Use the instrumental case</li>
            <li>Transportation and tool use</li>
            <li>Prepositions using the instrumental case</li>
          </ul>
        </li>
        <li>
          <strong>Body</strong>
          <ul>
            <li>Body parts</li>
            <li>Saying something hurts</li>
            <li>Sitting and standing</li>
          </ul>
        </li>
        <li>
          <strong>Direction</strong>
          <ul>
            <li>Imperatives for movement verbs</li>
            <li>Adverbs for direction</li>
            <li>Using cardinal directions</li>
            <li>More feminine genitive forms</li>
          </ul>
        </li>
        <li>
          <strong>Future</strong>
          <ul>
            <li>Imperfective verbs in the future</li>
            <li>Describing how long something lasts</li>
            <li>Lengths of time and common activities</li>
          </ul>
        </li>
        <li>
          <strong>Months</strong>
          <ul>
            <li>Months of the year</li>
            <li>Describing ranges in time: until/to, since/from</li>
            <li>Locative case for -r nouns</li>
          </ul>
        </li>
        <li>
          <strong>Adjectives 2</strong>
          <ul>
            <li>More common adjectives</li>
            <li>Exclaiming about things</li>
          </ul>
        </li>
        <li>
          <strong>Comparative</strong>
          <ul>
            <li>The comparative form of adjectives</li>
            <li>Possessive pronouns</li>
            <li>Saying &quot;next&quot; and &quot;another&quot;</li>
          </ul>
        </li>
        <li>
          <strong>Clothing</strong>
          <ul>
            <li>Words for clothing items and color</li>
            <li>Talking about wearing and trying on clothes</li>
            <li>Talking about selling and paying</li>
          </ul>
        </li>
        <li>
          <strong>Work</strong>
          <ul>
            <li>Words relating to work, teams and people</li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default LanguageCoverage;
